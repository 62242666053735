import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import uuidv4 from "uuid";
import { config } from "react-spring";
import rifa from '../../../assets/home/volante_rifa.jpg';
import ganador from '../../../assets/home/ganador.jpeg';
import nissan from '../../../assets/home/rifa-nissan.jpeg';
import foto1 from '../../../assets/home/fotos-05.jpg';
import foto2 from '../../../assets/home/foto-8.jpg';
import foto3 from '../../../assets/home/PROMOCIONAL.jpg';
import foto4 from '../../../assets/home/quienes-somos.jpg';
import bonoMillo from '../../../assets/home/Bono_millonario.jpeg';
import taxi from '../../../assets/home/Rifa_taxi.jpeg';
import { URL_landingPage } from "../../../utilities/odoo-config";

export default class CarruselHome extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle
  };

  data = [
    /* {
      key: uuidv4(),
      content: (
        <div style={{ position: 'relative', width: '100%', height: '80%' }}>
          <img src={rifa}
            alt="1"
            style={{  height: '100%' }} />
          <a
            href={URL_landingPage}
            style={{
              position: 'absolute',
              bottom: '5px',
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '10px 20px',
              backgroundColor: '#07989d',
              color: 'white',
              textDecoration: 'none',
              borderRadius: '5px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
            }}
          >
            Comprar
          </a>
        </div>
      ),
    }, */
    {
      content: <img src={foto1} alt="2" />,
      key: uuidv4(),
    },
    {
      content: <img src={foto2} alt="3" />,
      key: uuidv4(),
    },
    {
      content: <img src={foto3} alt="4" />,
      key: uuidv4(),
    },
    {
      content: <img src={foto4} alt="5" />,
      key: uuidv4(),
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  render() {
    return (
      <div style={{ width: "90%", height: "500px", margin: "0 auto" }}>
        <Carousel
          slides={this.data}
          goToSlide={this.state.goToSlide}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
        />
      </div>
    );
  }
}

import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';
import { clientSession } from '../utilities/odoo-config';
export const searchNumber = async (numero_boleta, product_id, callback) => {
    const config = await OdooConfig();
    const args = [[
        0, { numero_boleta, product_id }
    ]]

    const data = await Desencrypt();
    const params = {
        model: "movilgo.webservice",
        method: "buscarBoleta",
    }
    let finish = false;
    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
  
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }

                callback(response, true);
            });
        }

    })
}

export const searchPayments = async (tipo, valor, product_id, callback) => {
    const config = await OdooConfig();
    const args = [[ 0, { tipo, valor, product_id } ]]
   
    const data = await Desencrypt();
    const params = {
        model: "movilgo.webservice",
        method: "buscarAbonoRifa",
    }
    
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    //console.log("vendedor args",args)
    //console.log("vendedor cliente",client)
    client.connect(async(err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            await client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {
                    ///callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }

    })
}

export const getRifaResume = async (transaccion, callback) => {
    const config = await OdooConfig();
    const args = [[
        0, { transaccion }
    ]]
    const params = {
        model: "movilgo.webservice",
        method: "traerRifa",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: '8079',
        password: '1111'
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }

    })
}
export const ValidarNumero = async (id, callback) => {
    const config = await OdooConfig();
    const args = [[
        0, { id:id }
    ]]
    
    const params = {
        model: "movilgo.webservice",
        method: "validarBoleta",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username:clientSession.user,
        password: clientSession.password
    });
    //console.log("params de envio en validar bolet",params)
    //console.log("args de envio en ValidarBolet",args)
    //console.log("clien",client)

    client.connect((err, response) => {
        if (err) {
            console.log("Error de la conexion",err)
            return callback(false,response)
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {
                    console.log("Error despues del consumo",err)
                    return callback(false,err);
                }
                return callback(true,response);
            });
        }

    })
}

export const ValidarBoleta = async (id, callback) => {
    const config = await OdooConfig();
    const args = [[
        0, { id:id }
    ]]
    
    const params = {
        model: "movilgo.webservice",
        method: "validarBoleta2",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username:clientSession.user,
        password: clientSession.password
    });
    //console.log("params de envio en validar bolet",params)
    //console.log("args de envio en ValidarBolet",args)
    //console.log("clien",client)

    client.connect((err, response) => {
        if (err) {
            console.log("Error de la conexion",err)
            return callback(false,response)
        } else {
        //prueba---------------------------------------
            /* const response = {
                "datos": [
                    {
                        "direcion": "La casa",
                        "numero_promocional": "7648",
                        "apellido": "Gonzalez",
                        "CC": "1053824436",
                        "telefono": "3128470944",
                        "numero_resolucion": "022",
                        "numero": "3750",
                        "rifa_id": 39,
                        "email": "jeorgegonzalezvalencia@reactiva.com.co",
                        "celular": "3128470944",
                        "nombre": "Jeorge ",
                        "url_image": [
                            "https://i.imgur.com/Dxd5g00.jpeg",
                            "https://i.imgur.com/VRLkigD.jpeg",
                            "https://i.imgur.com/Z4fdLWz.jpeg",
                            "https://i.imgur.com/dd4fPlP.jpeg",
                            "https://i.imgur.com/yUJj0TU.jpeg",
                            "https://i.imgur.com/fDg35df.jpeg"
                        ],
                        "barrio": "La casa"
                    }
                ]
            }
            return callback(true,response); */
            //prueba------------------------------------------

            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {
                    console.log("Error despues del consumo",err)
                    return callback(false,err);
                }
                return callback(true,response);
            });
        }

    })
}
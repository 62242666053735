import React, { PureComponent } from 'react';
import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
//import { cambiarContrasena } from '../../../services/nuevaContraseña.service';

import './styles.css'
import { LoadingMovilGo } from '../../../shared/loadingMovilGo';
import { rifaIdBono2024_1, rifaIdBonoMillo, rifaIdBYD, rifaIdTaxi } from '../../../utilities/odoo-config';



const RotateMobile = (props) => {
    const { images, datosRifa, rifaId } = props
    var imageHorizontal = []
    console.log("data en RotateMobile", images, datosRifa, rifaId);
    if (rifaId == rifaIdBYD){
        imageHorizontal = images.slice(0, 2)
    }

    const [currentImageIndex, SetCurrentImageIndex] = useState(0);
    const [isFlipped, SetIsFlipped] = useState(false);


    const handleImageChange = (index) => {
        SetIsFlipped(true);
        setTimeout(() => {
            SetCurrentImageIndex(index);
            SetIsFlipped(false);
        }, 200); // Ajusta el tiempo de acuerdo a la duración de tu animación CSS
    };

    const handleNextImage = () => {
        if (rifaId == rifaIdBYD) {
            const nextIndex = (currentImageIndex + 1) % imageHorizontal.length;
            handleImageChange(nextIndex);
        } else {
            const nextIndex = (currentImageIndex + 1) % images.length;
            handleImageChange(nextIndex);
        }
    };

    const handlePreviousImage = () => {
        if (rifaId == rifaIdBYD) {
            const previousIndex = (currentImageIndex - 1 + imageHorizontal.length) % imageHorizontal.length;
            handleImageChange(previousIndex);
        } else {
            const previousIndex = (currentImageIndex - 1 + images.length) % images.length;
            handleImageChange(previousIndex);
        }
    };

    if (rifaId === rifaIdBonoMillo) {
        return (
            <div style={{ height: '80vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <div style={{ height: '100%', width: '100%', maxWidth: '460px', margin: '-4% auto 50px', position: 'relative' }}>
                    <div className='page-flip'>
                        <div style={{ marginTop: '7.5%', height: '98%', width: '100%', position: 'relative', borderRadius: '10px', boxShadow: '10px 5px 8px 0px rgba(0, 0, 0, 0.5), -10px 5px 8px 0px rgba(0, 0, 0, 0.5)' }}>
                            {images.map((imageUrl, index) => (
                                <div
                                    key={index}
                                    className={`page ${index === currentImageIndex ? 'page-front' : 'page-back'} ${isFlipped ? 'is-flipped' : ''}`}
                                >
                                    <img
                                        style={{ width: '100%', height: '100%', borderRadius: '10px' }}
                                        src={imageUrl.trim()}  // Elimina espacios en blanco antes y después de la URL
                                        alt={`Imagen ${index + 1}`}
                                    />
                                </div>
                            ))}

                            {currentImageIndex + 1 === 1 ? ( //valida la primera pagina
                                <>
                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        ...(rifaId === rifaIdBonoMillo ? { top: '93%', left: '190px' } : { top: '93%', left: '185px', })
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        ...(rifaId === rifaIdBonoMillo ? { top: '93%', left: '280px' } : { top: '93%', left: '275px', })
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 1 === currentImageIndex ? ( //valida la ultima pagina
                                <>
                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        ...(rifaId === rifaIdBonoMillo ? { top: '77%', left: '90px' } : { top: '78%', left: '85px', })
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        ...(rifaId === rifaIdBonoMillo ? { top: '77%', left: '180px' } : { top: '78%', left: '180px', })
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 2 === currentImageIndex ? ( //valida la penultima pagina
                                <>
                                    <p style={{ color: 'black', position: 'absolute', top: '11%', left: '270px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].nombre : 'No hay datos'}
                                    </p>
                                    <p style={{ color: 'black', position: 'absolute', top: '16.5%', left: '270px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].apellido : 'No hay datos'}
                                    </p>
                                    <p style={{ color: 'black', position: 'absolute', top: '22%', left: '270px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].CC : 'No hay datos'}
                                    </p>
                                    <p style={{ color: 'black', position: 'absolute', top: '28%', left: '290px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].direcion : 'No hay datos'}
                                    </p>
                                    <p style={{ color: 'black', position: 'absolute', top: '34%', left: '270px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].barrio : 'No hay datos'}
                                    </p>
                                    {/* <p style={{ color: 'black', position: 'absolute', top: '40%', left: '230px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].telefono : 'No hay datos'}
                                    </p> */}
                                    <p style={{ color: 'black', position: 'absolute', top: '40%', left: '270px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].celular : 'No hay datos'}
                                    </p>
                                    <p style={{ color: 'black', position: 'absolute', top: '45%', left: '270px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].email : 'No hay datos'}
                                    </p>
                                </>
                            ) : ''}

                        </div>

                    </div>

                </div>

                <div className="botones-container" style={{ top: '51%', right: '50%' }}>
                    <button onClick={handlePreviousImage} className="boton-anterior" >
                        Anterior
                    </button>
                    <p className="paginado" style={{ fontWeight: 'bold' }} >{currentImageIndex + 1}/{images.length}</p>

                    <button onClick={handleNextImage} className="boton-siguiente">
                        Siguiente
                    </button>
                </div>

            </div>
        )

    } else if (rifaId === rifaIdTaxi) {
        return (
            <div style={{ height: '80vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <div style={{ height: '100%', width: '100%', maxWidth: '460px', margin: '-4% auto 50px', position: 'relative' }}>
                    <div className='page-flip'>
                        <div style={{ marginTop: '7.5%', height: '98%', width: '100%', position: 'relative', borderRadius: '10px', boxShadow: '10px 5px 8px 0px rgba(0, 0, 0, 0.5), -10px 5px 8px 0px rgba(0, 0, 0, 0.5)' }}>
                            {images.map((imageUrl, index) => (
                                <div
                                    key={index}
                                    className={`page ${index === currentImageIndex ? 'page-front' : 'page-back'} ${isFlipped ? 'is-flipped' : ''}`}
                                >
                                    <img style={{ width: '100%', height: '100%', borderRadius: '10px' }} src={imageUrl.trim()} alt={`Imagen ${index + 1}`} />
                                </div>
                            ))}
                            {currentImageIndex + 1 === 1 ? ( //valida la primera pagina
                                <>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', position: 'absolute', top: '42%', left: '60px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', position: 'absolute', top: '42%', left: '160px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 1 === currentImageIndex ? ( //valida la ultima pagina
                                <>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', position: 'absolute', top: '43%', left: '120px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', position: 'absolute', top: '62%', left: '120px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 2 === currentImageIndex ? ( //valida la penultima pagina
                                <>
                                    <p style={{ color: 'black', position: 'absolute', top: '14%', right: '150px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].nombre : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '22%', right: '150px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].apellido : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '30%', right: '150px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].CC : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '38%', right: '150px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].telefono : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '45%', right: '150px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].celular : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '53%', right: '110px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].email : 'No hay datos'}</p>
                                </>
                            ) : ''}

                        </div>

                    </div>

                </div>

                <div className="botones-container" style={{ top: '51%', right: '50%' }}>
                    <button onClick={handlePreviousImage} className="boton-anterior" >
                        Anterior
                    </button>
                    <p className="paginado" style={{ fontWeight: 'bold' }} >{currentImageIndex + 1}/{images.length}</p>

                    <button onClick={handleNextImage} className="boton-siguiente">
                        Siguiente
                    </button>
                </div>

            </div>
        )
    } else if (rifaId === rifaIdBYD) {
        return (
            <div style={{ height: '80vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <div style={{ height: '100%', width: '100%', maxWidth: '750px', maxHeight:'550px', margin: '-4% auto 50px', position: 'relative' }}>
                    <div className='page-flip'>
                        <div style={{ marginTop: '7.5%', height: '98%', width: '100%', position: 'relative', borderRadius: '10px', boxShadow: '10px 5px 8px 0px rgba(0, 0, 0, 0.5), -10px 5px 8px 0px rgba(0, 0, 0, 0.5)' }}>
                            {imageHorizontal.map((imageUrl, index) => (
                                <div
                                    key={index}
                                    className={`page ${index === currentImageIndex ? 'page-front' : 'page-back'} ${isFlipped ? 'is-flipped' : ''}`}
                                >
                                    <img style={{ width: '100%', height: '100%', borderRadius: '10px' }} src={imageUrl.trim()} alt={`Imagen ${index + 1}`} />
                                </div>
                            ))}
                            {currentImageIndex + 1 === 1 ? ( //valida la primera pagina
                                <>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', position: 'absolute', top: '15%', left: '40px', fontSize: '20px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {imageHorizontal.length - 1 === currentImageIndex ? ( //valida la ultima pagina
                                <>
                                    <h6 style={{ color: 'red', fontWeight: 'bold', position: 'absolute', top: '50%', left: '650px', fontSize: '30px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>

                                    <p style={{ color: 'black', position: 'absolute', top: '13.5%', left: '540px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].nombre + " " +datosRifa[0].apellido: 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '22%', left: '520px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].CC : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '29%', left: '515px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].celular : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '37%', left: '430px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '12px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].email : 'No hay datos'}</p>
                                </>
                            ) : ''}


                        </div>

                    </div>

                </div>

                <div className="botones-container" style={{ top: '51%', right: '50%' }}>
                    <button onClick={handlePreviousImage} className="boton-anterior" >
                        Anterior
                    </button>
                    <p className="paginado" style={{ fontWeight: 'bold' }} >{currentImageIndex + 1}/{imageHorizontal.length}</p>

                    <button onClick={handleNextImage} className="boton-siguiente">
                        Siguiente
                    </button>
                </div>

            </div>
        )
    }

}

export default RotateMobile
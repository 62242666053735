import React, { PureComponent, useEffect } from 'react';
import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
//import { cambiarContrasena } from '../../../services/nuevaContraseña.service';
import { ValidarBoleta } from '../../../services/rifa.service';
import { Image } from 'react-bootstrap';
import SlideAnimation from './DeslizarDercha';
import './styles.css'
import { LoadingMovilGo } from '../../../shared/loadingMovilGo';
import { rifaIdBono2024_1, rifaIdBonoMillo, rifaIdBYD, rifaIdTaxi } from '../../../utilities/odoo-config';



const BoletaMobile = (props) => {
    const { images, loading, datosRifa, rifaId } = props
    var imageVertical = []
    if (rifaId == rifaIdBYD){
        imageVertical = images.slice(-2)//obtiene los ultimos dos imagenes 
    }
    const [currentImageIndex, SetCurrentImageIndex] = useState(0);
    const [isFlipped, SetIsFlipped] = useState(false);
    const [smallScreen, SetSmallScreen] = useState(false);


    useEffect(() => {
        // Función para verificar si se está visualizando desde un celular
        const checkMobile = () => {
            const smallerScreen = window.innerWidth < 400;
            SetSmallScreen(smallerScreen);
        };

        checkMobile();

        window.addEventListener('resize', checkMobile);

        // Limpiar el evento de escucha al desmontar el componente
        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    const handleImageChange = (index) => {
        SetIsFlipped(true);
        setTimeout(() => {
            SetCurrentImageIndex(index);
            SetIsFlipped(false);
        }, 200); // Ajusta el tiempo de acuerdo a la duración de tu animación CSS
    };

    const handleNextImage = () => {
        if (rifaId == rifaIdBYD) {
            const nextIndex = (currentImageIndex + 1) % imageVertical.length;
            handleImageChange(nextIndex);
        } else {
            const nextIndex = (currentImageIndex + 1) % images.length;
            handleImageChange(nextIndex);
        }
    };

    const handlePreviousImage = () => {
        if (rifaId == rifaIdBYD) {
            const previousIndex = (currentImageIndex - 1 + imageVertical.length) % imageVertical.length;
            handleImageChange(previousIndex);
        } else {
            const previousIndex = (currentImageIndex - 1 + images.length) % images.length;
            handleImageChange(previousIndex);
        }
    };


    if (rifaId === rifaIdBonoMillo ) {
        return (
            <div style={{ height: '62vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>

                <SlideAnimation />
                <div style={{ height: '100%', width: '100%', maxWidth: '390px', margin: '0 auto 50px', position: 'relative' }}>
                    <div className='page-flip'>
                        <div style={{ marginTop: '7.5%', height: '98%', width: '100%', position: 'relative', borderRadius: '10px', overflow: 'auto', boxShadow: '10px 5px 8px 0px rgba(0, 0, 0, 0.5), -10px 5px 8px 0px rgba(0, 0, 0, 0.5)' }}>
                            {images.map((imageUrl, index) => (
                                <div
                                    key={index}
                                    className={`page ${index === currentImageIndex ? 'page-front' : 'page-back'} ${isFlipped ? 'is-flipped' : ''}`}
                                >
                                    <img style={{ height: '100%', borderRadius: '10px' }} src={imageUrl.trim()} alt={`Imagen ${index + 1}`} />
                                </div>
                            ))}
                            {currentImageIndex + 1 === 1 ? ( //valida la primera pagina
                                <>
                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        ...( smallScreen ? { top: '93%', left: '240px', } : { top: '91%', left: '280px' })
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>

                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        ...( smallScreen ? { top: '93%', left: '340px'} : { top: '91%', left: '430px' })
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 1 === currentImageIndex ? ( //valida la ultima pagina
                                <>
                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        ...(smallScreen ? { top: '77%', left: '110px', } : { top: '78%', left: '140px' } )
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                    <h6 style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: '3',
                                        ...(smallScreen ? { top: '77%', left: '220px', } : { top: '78%', left: '270px' })
                                    }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 2 === currentImageIndex ? ( //valida la penultima pagina
                                <>
                                    <p style={{ color: 'black', position: 'absolute', top: '11%', left: smallScreen ? '370px' : '450px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px', whiteSpace: 'nowrap' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].nombre : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '16.5%', left: smallScreen ? '370px' : '450px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px', whiteSpace: 'nowrap' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].apellido : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '22%', left: smallScreen ? '370px' : '450px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px', whiteSpace: 'nowrap' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].CC : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '28%', left: smallScreen ? '400px' : '500px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px', whiteSpace: 'nowrap' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].direcion : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '34%', left:  smallScreen ? '360px' : '440px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px', whiteSpace: 'nowrap' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].barrio : 'No hay datos'}</p>

                                    {/* <p style={{ color: 'black', position: 'absolute', top: '42%', left: smallScreen ? '360px' : '-100px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px', whiteSpace: 'nowrap' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].telefono : 'No hay datos'}</p> */}

                                    <p style={{ color: 'black', position: 'absolute', top: '40%', left:smallScreen ? '360px' : '440px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px', whiteSpace: 'nowrap' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].celular : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '45%', left: smallScreen ? '360px' : '440px', transform: 'translateY(-50%)', zIndex: '3', fontSize: '12px', whiteSpace: 'nowrap' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].email : 'No hay datos'}</p>
                                </>
                            ) : ''}

                        </div>

                    </div>

                </div>
                <div className="botones-container" style={{ top: '51%', right: '50%' }}>
                    <button disabled={loading} onClick={handlePreviousImage} className="boton-anterior">
                        Anterior
                    </button>
                    <p className="paginado" style={{ fontWeight: 'bold' }}> {currentImageIndex + 1}/{images.length}</p>
                    <button disabled={loading} onClick={handleNextImage} className="boton-siguiente">
                        Siguiente
                    </button>
                </div>
            </div>
        )

    } else if (rifaId === rifaIdTaxi) {

        return (
            <div style={{ height: '62vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>

                <SlideAnimation />
                <div style={{ height: '100%', width: '100%', maxWidth: '390px', margin: '0 auto 50px', position: 'relative' }}>
                    <div className='page-flip'>
                        <div style={{ marginTop: '7.5%', height: '98%', width: '100%', position: 'relative', borderRadius: '10px', overflow: 'auto', boxShadow: '10px 5px 8px 0px rgba(0, 0, 0, 0.5), -10px 5px 8px 0px rgba(0, 0, 0, 0.5)' }}>
                            {images.map((imageUrl, index) => (
                                <div
                                    key={index}
                                    className={`page ${index === currentImageIndex ? 'page-front' : 'page-back'} ${isFlipped ? 'is-flipped' : ''}`}
                                >
                                    <img style={{ height: '100%', borderRadius: '10px' }} src={imageUrl.trim()} alt={`Imagen ${index + 1}`} />
                                </div>
                            ))}
                            {currentImageIndex + 1 === 1 ? ( //valida la primera pagina
                                <>
                                    <h6 style={{ color: 'red', fontSize: '40px', fontWeight: 'bold', position: 'absolute', top: '42%', left: '100px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                    <h6 style={{ color: 'red', fontSize: '40px', fontWeight: 'bold', position: 'absolute', top: '42%', left: '235px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 1 === currentImageIndex ? ( //valida la ultima pagina
                                <>
                                    <h6 style={{ color: 'red', fontSize: '40px', fontWeight: 'bold', position: 'absolute', top: '43%', left: '200px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                    <h6 style={{ color: 'red', fontSize: '40px', fontWeight: 'bold', position: 'absolute', top: '61%', left: '200px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero_promocional : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {images.length - 2 === currentImageIndex ? ( //valida la penultima pagina
                                <>
                                    <p style={{ color: 'black', position: 'absolute', top: '14%', right: '-100px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '18px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].nombre : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '22%', right: '-100px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '18px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].apellido : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '30%', right: '-105px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '18px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].CC : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '37%', right: '-100px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '18px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].telefono : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '45%', right: '-100px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '18px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].celular : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '53%', right: '-150px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '18px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].email : 'No hay datos'}</p>
                                </>
                            ) : ''}

                        </div>

                    </div>

                </div>
                <div className="botones-container" style={{ top: '51%', right: '50%' }}>
                    <button disabled={loading} onClick={handlePreviousImage} className="boton-anterior">
                        Anterior
                    </button>
                    <p className="paginado" style={{ fontWeight: 'bold' }}> {currentImageIndex + 1}/{images.length}</p>
                    <button disabled={loading} onClick={handleNextImage} className="boton-siguiente">
                        Siguiente
                    </button>
                </div>
            </div>
        )
    } else if (rifaId === rifaIdBYD) {

        return (
            <div style={{ height: '70vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>

                {/* <SlideAnimation /> */}
                <div style={{ height: '100%', width: '100%', maxWidth: '390px', margin: '0 auto 50px', position: 'relative' }}>
                    <div className='page-flip'>
                        <div style={{ marginTop: '7.5%', height: '95%', width: '100%', position: 'relative', borderRadius: '10px', overflow: 'auto', boxShadow: '10px 5px 8px 0px rgba(0, 0, 0, 0.5), -10px 5px 8px 0px rgba(0, 0, 0, 0.5)' }}>
                            {imageVertical.map((imageUrl, index) => (
                                <div
                                    key={index}
                                    className={`page ${index === currentImageIndex ? 'page-front' : 'page-back'} ${isFlipped ? 'is-flipped' : ''}`}
                                >
                                    <img style={{ width:'100%', borderRadius: '10px', height:'100%' }} src={imageUrl.trim()} alt={`Imagen ${index + 1}`} />
                                </div>
                            ))}
                            {currentImageIndex + 1 === 1 ? ( //valida la primera pagina
                                <>
                                    <h6 style={{ color: 'red', fontSize: '20px', fontWeight: 'bold', position: 'absolute', top: '7%', left: '39px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>
                                </>
                            ) : ''}

                            {imageVertical.length - 1 === currentImageIndex ? ( //valida la ultima pagina
                                <>
                                    <h6 style={{ color: 'red', fontSize: '30px', fontWeight: 'bold', position: 'absolute', top: '63%', left: '265px', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].numero : 'No hay datos'}
                                    </h6>

                                    <p style={{ color: 'black', position: 'absolute', top: '40%', left: '135px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '16px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].nombre + " " +datosRifa[0].apellido: 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '45%', left: '110px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '16px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].CC : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '50%', left: '100px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '16px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].celular : 'No hay datos'}</p>

                                    <p style={{ color: 'black', position: 'absolute', top: '54.5%', left: '30px', transform: 'translate(50%, -50%)', zIndex: '3', fontSize: '13px' }}>
                                        {datosRifa.length > 0 ? datosRifa[0].email : 'No hay datos'}</p>
                                </>
                            ) : ''}

                        </div>

                    </div>

                </div>
                <div className="botones-container" style={{ top: '51%', right: '50%' }}>
                    <button disabled={loading} onClick={handlePreviousImage} className="boton-anterior">
                        Anterior
                    </button>
                    <p className="paginado" style={{ fontWeight: 'bold' }}> {currentImageIndex + 1}/{imageVertical.length}</p>
                    <button disabled={loading} onClick={handleNextImage} className="boton-siguiente">
                        Siguiente
                    </button>
                </div>
            </div>
        )
    }


}

export default BoletaMobile